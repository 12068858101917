
export class VendorData {
  constructor() {
    this.vendorTypeId = null;
    this.companyName = null;
    this.companyUrl = null;
    this.userName = "";
    this.password = "";
    this.createdOn = new Date();
    this.otherCategory = null
  }

  public vendorTypeId: any;
  public companyName: any;
  public companyUrl: any;
  public userName: any;
  public password: any;
  public createdOn: Date;
  public otherCategory: any;


}





