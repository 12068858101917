

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../config";
import Axios from "axios";
import { authHeader } from "../../services/auth";
import { VendorData } from "../../models/super-admin/vendorData.model";
import CommonDeleteModal from "../../common-components/CommonDeleteModal.vue";
import { list } from "suneditor/src/plugins";
//import { MASTER_CREDENTIALS_CATEGORY } from "../../constants/constants";

@Component({
  components: {
    CommonDeleteModal,
  },
})
export default class MasterCredentials extends Vue {
  /* eslint-disable */

  public vendorData: any = new VendorData();
  //public masterCredentialsList = [];
  public categoryList = [];
  public venderDetailsList = [];
  public isEdit = false;
  public masterId =  0
  public passwordFieldType = "password";
  
  //public masterCredentialsCategory= MASTER_CREDENTIALS_CATEGORY;

  public async saveMasterCredentials() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "common/master-credentials/saveMasterCredentials",
        this.vendorData,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success(
          this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
        );
        this.vendorData = {};
        this.$modal.hide("addMasterCredentials");
        this.getMasterCredentials();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getMasterCredentials() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}common/master-credentials/getMasterCredentials`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.categoryList = response.data.vendorTypes;
        this.venderDetailsList = response.data.promise;
        // if(this.masterId == 0){
        // this.masterId = this.categoryList.length > 0 ? this.categoryList[this.categoryList.length - 1].id + 1  : 0
        
        }
      // }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async deleteMasterCredentials(data) {
    try {
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${data.companyName} Master Credentials ?`,
        data: { id: data._id },
        apiPath: "common/master-credentials/deleteMasterCredentials",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public openMasterCredentialsModal() {
    try {
      this.$modal.show("addMasterCredentials");
      //this.getMasterCredentials();
      this.isEdit = false;
      this.vendorData = new VendorData();
    } catch (error) {
      console.log(error);
    }
  }

public switchVisibility() {
    this.passwordFieldType =
      this.passwordFieldType === "password" ? "text" : "password";
  }

  public checkCategory(){
    // let lists = this.categoryList.length
    // console.log( this.masterId,'masterId ');
    // console.log(lists,'lists');
    
    // let master = this.categoryList[lists-1]
    // console.log(master ,'master ');
    
    this.masterId = this.masterId
    

  }

  public cancel() {
    this.$modal.hide("addMasterCredentials");
  }

  async mounted() {
   await this.getMasterCredentials();
  }
}
